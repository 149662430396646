import React from "react";

import css from "./MeInANutshell.module.scss";

export default function MeInANutshell() {
  return (
    <div className={css.summaryBox}>
      <p className={css.summaryText}>
        I love people and building elegant tools that delight.
        <br />
        <span className={css.summaryPitch}>
          Full-stack web app developer with a DevOps streak.
        </span>
        <br />
        Interested in problem solving in distributed systems
        <br />
        from the ground up.
      </p>
    </div>
  );
}
