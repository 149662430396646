import React from "react";

import css from "./experience.module.scss";

const Experience = () => (
  <section className={css.experience}>
    <div className={css.experienceHeading}>
      <h2 className={css.experienceHeadingText}>Work</h2>
    </div>

    <div className={css.experienceContent}>
      <div className={css.job}>
        <div className={css.jobTitle}>Senior Software Engineer</div>
        <div className={css.companyAndDates}>
          <span className={css.jobCompany}>
            <a href="https://www.instinct.vet/">Instinct Science</a>
          </span>
          <span className={css.jobTenure}>2020 - Date.now()</span>
        </div>

        <p className={css.jobSummary}>
          Full-stack React development of veterinary electronic medical records
          in an early-stage startup. TypeScript and GraphQL against an Elixir
          backend running on AWS EKS (Kubernetes).
        </p>

        <div className={css.jobHighlights}>
          <dl>
            <dt>Team Contributions</dt>
            <dd>
              <ul>
                <li>
                  Offered insights from experience on efficient team workflow
                  practices, including CI/CD and git branching strategies
                </li>
                <li>
                  Led a book group on the impact of electronic medical records
                  in human health. Directly applicable to our work in animal
                  EMR.
                </li>
              </ul>
            </dd>
          </dl>

          <dl>
            <dt>Technical Contributions</dt>
            <dd>
              <ul>
                <li>
                  Introduced full-stack observability using Datadog with
                  OpenTelemetry while on the Platform team
                </li>
                <li>
                  Migrated to Vite ES modules from webpack, improving build
                  times and client bundle sizes
                </li>
                <li>
                  Built a feature flag management system to give control to
                  non-developer roles. Simultaneously pitched senior leadership
                  for purchasing an off-the-shelf feature management system
                  (LaunchDarkly) to give us superior tooling and allow our
                  company to focus on its core competencies.
                </li>
                <li>
                  Implemented a pattern for number and currency localization to
                  support our international customer base
                </li>
                <li>
                  Overhauled our app's direct-to-label printer capabilities,
                  adding support for multiple devices and user-friendly error
                  reporting
                </li>
                <li>
                  Optimized image content on the app's landing page, reducing
                  load times for our many low-end devices
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>

      <div className={css.job}>
        <div className={css.jobTitle}>Open Source Maintainer</div>
        <div className={css.companyAndDates}>
          <span className={css.jobCompany}>
            <a href="https://github.com/actualbudget/">Actual Budget</a>
          </span>
          <span className={css.jobTenure}>2021 - 2023</span>
        </div>

        <p className={css.jobSummary}>
          Core maintainer handling code reviews and community decisions.
        </p>
        <ul>
          <li>
            Ported retired React Native code to a responsive web app to set the
            early direction for mobile support.
          </li>
          <li>Helped set up the initial CI and Docker Hub image pipeline.</li>
          <li>
            Upgrade to React Router v6 and current routing best practices;
            investigating web-native view transitions
          </li>
        </ul>
      </div>

      <div className={css.job}>
        <div className={css.jobTitle}>
          Software Engineer -> Senior -> Staff Engineer
        </div>
        <div className={css.companyAndDates}>
          <span className={css.jobCompany}>
            <a href="https://rally1.rallydev.com/">Rally Software</a> / CA
            Technologies /{" "}
            <a href="https://www.broadcom.com/products/software/value-stream-management/rally">
              Broadcom
            </a>
          </span>
          <span className={css.jobTenure}>2014 - 2020</span>
        </div>

        <p className={css.jobSummary}>
          Feature development in Rally, the $100M+ agile application lifecycle
          management product, working in an event-driven microservices
          environment continuously delivered against a backdrop of more legacy
          frameworks than you can shake a stick at. Extensive test coverage and
          robust production observability. Helped support legacy Clojure/mongoDB
          microservices.
        </p>

        <div className={css.jobHighlights}>
          <dl>
            <dt>Team Contributions</dt>
            <dd>
              <ul>
                <li>
                  Mentor teammates in JavaScript, React, and Redux best
                  practices through code reviews, pair programming, training
                </li>
                <li>
                  Advise teammates in best practices for using our internal UI
                  design system
                </li>
                <li>
                  Organizer of an internal Front-end Developers Guild where best
                  practices are discussed and org-wide working agreements
                  decided
                </li>
                <li>
                  Volunteer “steward” for our front-end repository, tasked with
                  fostering developer productivity and a healthy codebase
                </li>
              </ul>
            </dd>
          </dl>

          <dl>
            <dt>Technical Contributions</dt>
            <dd>
              <ul>
                <li>
                  Converted our overhauled app's unit testing suite to Jest with
                  @testing-library (from Jasmine/enzyme), pruning 800 test
                  suites containing 9000 tests down to 130 suites containing the
                  1500 still-relevant tests
                </li>
                <li>
                  Primary author/maintainer of an internal Node-based error
                  reporting tool for tracking client-side errors. Using this
                  tool, engineering collectively reduced the 5-day JS error
                  count from over 250 to about 15.
                </li>
                <li>
                  Implemented Bugsnag JavaScript error tracking after 3 years
                  using the above tool
                </li>
                <li>
                  Revamped our front-end documentation content and build process
                  to catch up to the current state of our app
                </li>
                <li>
                  Provide detailed bug reports and minimal bug reproductions to
                  the team building our internal design system
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>

      <div className={css.job}>
        <div className={css.jobTitle}>Volunteer Frontend Developer</div>
        <div className={css.companyAndDates}>
          <span className={css.jobCompany}>
            Colorado Citizen Software Engineers
          </span>
          <span className={css.jobTenure}>Spring 2020</span>
        </div>

        <p className={css.jobSummary}>
          Developed a COVID-19 triage hotline for uninsured Coloradans on a
          small team using TypeScript and Postgres, Twilio, AWS, and an
          electronic medical records system.
        </p>
      </div>

      <div className={css.job}>
        <div className={css.jobHeading}>
          <span className={css.jobTitle}>
            Data Analyst -> Agile Product Owner -> Software Developer
          </span>
          <span className={css.companyAndDates}>
            <span className={css.jobCompany}>Clearwater Analytics</span>
            <span className={css.jobTenure}>2007 - 2014</span>
          </span>
        </div>
        <div className={css.jobHighlights}>
          <ul>
            <li>
              Built RESTful webservices in Java using JAX-RS 2.0, including the
              underlying DAO and SQL stored procedures.
            </li>
            <li>
              Designed and implemented a set of AJAX backoffice tools using
              Twitter Bootstrap in support of legacy database update operations
              previously performed by analysts via direct SQL manipulation
            </li>
            <li>
              Personally upgraded a backoffice system from Spring 2.0 to Spring
              3.0 in order to provide AJAX support in the above tools and
              enhance future maintainability. Migrated from the Acegi security
              framework to its successor, Spring Security.
            </li>
            <li>
              Helped build a proof-of-concept transaction processing service
              with HTTP long-polling
            </li>
            <li>
              As a Data Analyst, designed SQL queries to help the sales and
              executive teams answer questions about our customers and their
              usage patterns
            </li>
            <li>
              Enormously grateful for the chance to pursue new roles when I
              wanted greater technical challenges
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default Experience;
